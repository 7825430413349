import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
// import { Link } from "react-router-dom";

import styles from "./list.module.css";
import { DropDownArrow } from "../image-imports";
import TooltipBottom from "../tooltip-bottom";
import Pagination from "./pagination";
import Spinner from "../spinner";
import tMap from './tableViewMap'
import { useModal } from '../../../ModalContext'

const List = (props) => {
    const [activePage, setActivePage] = useState(1)
    const history = useHistory();
    const modal = useModal();

    const inlineStyles = {}

    props.single && (inlineStyles["width"] = "600px");
    props.width && (inlineStyles["width"] = props.width);
    props.centerText && (inlineStyles["textAlign"] = "center");

    const pageHandler = async (pageNumber) => {
        if (!props.data[pageNumber-1]) {

            try {
                await props.getMoreData(pageNumber)
                setActivePage(pageNumber)
            } catch (error) {
                return false
            }

        } else {
            setActivePage(pageNumber)
        }
    }

    useEffect(() => {
        if (props.data && props.data.length === 1 && activePage !== 1) {
            setActivePage(1)
        }
    }, [props.data, activePage])

    const displaySingleRun = (id) => {
        modal.setModal({displayRunId: id})
    }

    let title = (
        <h1 className={styles.table_title}>
            {tMap[props.title] || props.title}
        </h1>
    )

    const mapDropDown = (menuOptions) => {
        let o = [];
        menuOptions.map(([name, handler]) => o.push(<li key={name} onClick={async () => { await handler(); setActivePage(1) }}>{name}</li>))

        return <ul className={styles.ul}>{o}</ul>
    }
    
    let drop = props.titleDropDownOptions
    if (drop) {
        title = (
            <TooltipBottom 
                item={
                    <h1 className={styles.table_title}>
                        {tMap[props.title] || props.title} <span className={styles.drop}><DropDownArrow /></span>
                    </h1>
                }
                tooltip={mapDropDown(drop)}
            />
        )
    }
    //history.push(`/records/${e.steamid}`)
    let list = null
    const makeClick = (arr) => {
        let r;
        props.records && (r = () => displaySingleRun(arr[0]));

        if (props.steamid || props.click) {
            arr.forEach(e => {
                if (e.steamidClick) {
                    r = () => history.push(`/players/${e.steamidClick}`)
                }
            })
        }

        return r
    }

    if (typeof props.data === 'string') {
        list = <p className={styles.not_found}>{props.data}</p>
    } else if (typeof props.data === 'object' && Array.isArray(props.data) && props.data.length > 0 && props.data[activePage-1]) {
        list = (
            <table 
            className={styles["content-table"]} 
            style={inlineStyles}
            >
                <thead>
                    <tr style={props.centerText && {"textAlign": "center"}}>
                        {props.columnNames.map(name => {
                            return <th key={name} className={(name === '') ? styles.dif : ''}>{name}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.data[activePage-1].map((arr, index) => {
                        let slicedId = arr.slice(1)
                        return (
                            <tr 
                                key={arr[0] + index*100000} 
                                onClick={makeClick(arr)}
                                style={props.records || props.steamid ? {"cursor": "pointer"} : {"cursor": "default"}}
                                >
                                {slicedId.map((cell, index) => {
                                        if (!cell.steamidClick) {
                                            return (<td key={arr[0] + '+' + index}>{cell}</td>)
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    let pages = null;

    props.data && (props.data.length > 1) && Array.isArray(props.data) && (pages = (
        <div>
            <Pagination 
                data={props.data}
                pageClickHandler={pageHandler}
                activePage={activePage}
                maxPages={props.maxPages}
            /> 
        </div>
    ))

    return (
        props.data && props.data.length > 0 ?
        <React.Fragment>
            <div className={styles.table_wrapper}>
                {title}
                {list}
            </div>
                {pages}
        </React.Fragment> 
        : <Spinner />
    );
};

export default List;
