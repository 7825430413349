import React, { useState } from 'react';
import axios from 'axios'

import styles from './settings.module.css'
import { AJAX } from '../config'

const ChangePoints = (props) => {

    const [points, setPoints] = useState(null)
    const [saveButton, setSaveButton] = useState('Save')

    const makeDropDownList = () => {
        let options = [];

        for (let i = 0; i < 31; i++) {
            options.push(<option value={i*10} key={i}>{i*10}</option>)
        }

        return options
    }

    const pointsChangeHandler = (event) => {
        setPoints(event.target.value)
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (points) {
            setSaveButton('Saving...')

            try {
                const config = {
                    url: AJAX + 'admin/changepoints',
                    method: 'post',
                    withCredentials: true,
                    data: { points: points, map: props.map }
                };
                await axios.request(config); 
                setSaveButton('Saved')
            } catch (error) {
                setSaveButton('Error saving')
            }

        }
    }
    
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.options}>
                    <div className={styles.option}>
                    <label htmlFor="points">Points</label>
                        <select 
                            name="points" 
                            id="points" 
                            className={styles.dropdown} 
                            onChange={pointsChangeHandler}>
                            <option value={points} hidden>Select</option>
                            {makeDropDownList()}
                        </select> 
                    </div>
                    <div className={styles.save} onClick={onSubmitHandler}>
                        <div className={styles.button}>{saveButton}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ChangePoints