import React, { useState } from "react";

import styles from './tooltip-bottom.module.css'

const TooltipBottom = (props) => {
    const [clicked, setClicked] = useState(false)

    return (
        <span className={styles.tooltip} onClick={() => setClicked(prev => !prev)}>{props.item}
            <span className={`${styles.tooltiptext} ${clicked ? styles.visible : styles.hidden}`}>
                {props.tooltip}
            </span>
        </span>
    );
};
export default TooltipBottom;
