import React, { useState, useEffect } from "react";
import axios from 'axios'

import "./modal.css";
import Record from "./record";
import Spinner from "../spinner";
import { isObjEmpty } from '../funcs'
import { AJAX } from '../config'
import { useModal } from '../../../ModalContext'

const Modal = (props) => {

    const [content, setContent] = useState(null)
    const modal = useModal();

    const displayText = (t) => {
        return <p className={"modal-center"}>{t}</p>
    }

    useEffect(() => {
        const displayRunId = async (id) => {
    
            try {
                let {data: time} = await axios.get(`${AJAX}records/id/${id}`)
                if (!isObjEmpty(time)) {
                    setContent( <Record time={time} />)
                } else {
                    setContent( displayText('Found no record at given ID.') )
                }
            } catch (error) {
                setContent( displayText('Error fetching') )
            }
        
        }
    
        if (modal && modal.active) {
            if (typeof modal.active === 'object' && modal.active.displayRunId) {
                setContent(<span className={"modal-center"}><Spinner /></span>)
                displayRunId(modal.active.displayRunId)
            } else {
                setContent(modal.active)
            }
        } else {
            setContent(null)
        }
        
    }, [modal])

    return (
        modal && modal.active ? <div className={"modal"} onClick={() => modal.setModal(null)}>
            <div className={"modal-box"} onClick={(e) => e.stopPropagation()}>
                <span className={"modal-close"} onClick={() => modal.setModal(null)}>
                    &times;
                </span>
                {content}
            </div>
        </div> : null
    )

}


export default Modal
