import React, { useState } from 'react';
import axios from 'axios'
import { Helmet } from "react-helmet-async";

import { openInNewTab } from '../../../../shared/funcs'
import { AJAX } from '../../../../shared/config'
import useMountEffect from "../../../../hooks/mount";
import { YTLogo, HammerLogo } from '../../../../shared/image-imports'
import styles from './community.module.css'
import styles2 from '../../../../shared/list/more-button.module.css'
import Spinner from "../../../../shared/spinner";


const Community = (props) => {
    const [list, setList] = useState([])
    const [lastId, setLastId] = useState(null)
    const [loading, setLoading] = useState()
    const [bottom, setBottom] = useState(true)
    
    const createEntry = (obj) => {

        const types = {
            map: {
                linkBase: "https://gamebanana.com/maps/",
                logo: HammerLogo,
                
                // info
                css: "CS:S map",
                csgo: "CS:GO map",
                climb: "Climb map"
            },
            video: {
                linkBase: "https://www.youtube.com/watch?v=",
                logo: YTLogo,
                text: 'Video'
            }
        }
        
        if (types.hasOwnProperty(obj.event_type)) {
            let { linkBase, logo } = types[obj.event_type]
            let inf = types[obj.event_type][obj.info || "text"]

            return(
                <li className={styles.list_item} onClick={() => openInNewTab(linkBase+obj.link)} key={obj.id}>
                    <span className={styles.pic_container}>
                        <img className={styles.pic} src={logo} alt=""/>
                    </span>
                    <span className={styles.text_container}>
                        <span className={styles.text}>
                            <p className={styles.list_item_title}>{obj.title}</p>
                            <p>{`${inf} by ${obj.creator}`}</p>
                        </span>
                    </span>
                </li>
            )
        } else {
            return null
        }
    
    }

    const load = async (id) => {
            setLoading(true)
            setBottom(null)
            try {
                const response = await axios.get(`${AJAX}community/recent/${id ? id : ''}`)
                const { data } = response;
                if (response.status === 200) {
                    if (data.length > 0) {
                        let newLastId = 1;
                        let newData = list; 
                        for (let i = 0; i < data.length; i++) {
                            newData.push(createEntry(data[i]))
                            newLastId = data[i].id; 
                        }
                        setLastId(newLastId)
                        setList(newData)
                    } else {
                        setBottom('All community content loaded.')
                    }
                    setLoading(false)
                } else {
                    throw new Error(response.status)
                }
            } catch (error) {
                setLoading(false)
                setBottom('There was an error loading more content.')
            }
    }
    
    const initialLoad = () => {
        load()
    }
    
    useMountEffect(initialLoad)

    const moreHandler = () => {
        load(lastId || 1)

    }

    return (
        <div className={styles.container}>
        <Helmet>
            <title>Community</title>
            <meta name="description" content="Community feed" />
        </Helmet>
            <h1 className={styles.title}>New maps and videos</h1>
            {list && <ul className={styles.ul}>{list}</ul>}
            {loading && <Spinner />}
            {bottom && <p className={styles.bottom}>{bottom}</p>}
            {!loading && !bottom && <p className={styles2.more_button} onClick={moreHandler}>Load more...</p>}
        </div>
    )
}
export default Community