import React from "react";

import * as countries from "./countries";
import styles from './flags-list.module.css'
import Tooltip from './tooltip'

const Flags = (props) => {
    let country = countries.namesShort[props.country]
    return (
        <Tooltip 
            item={ <img src={countries[props.country]} className={styles.flag} alt="" /> } tooltip={country} />
    );
};

export default Flags;
