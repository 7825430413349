import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from "react-helmet-async";

import styles from "./records.module.css";
import Searchbox from "../../../../shared/searchbox";
import List from "../../../../shared/list/list";
import { formatTimesForList, insertToArray, splitArray } from "../../../../shared/funcs";
import { AJAX } from "../../../../shared/config";
import ManualRecord from "../../../../shared/modal/add-manual-record";
import tMap from "../../../../shared/list/tableViewMap";
import { useModal } from '../../../../../ModalContext'
import { useUser } from '../../../../../UserContext'

const Records = (props) => {
    const [title, setTitle] = useState(null)
    const [data, setData] = useState(null)
    const [loaded, setLoaded] = useState(null)
    const [columnNames, setColumnNames] = useState(["Player", "Map", "Time", ""])
    const history = useHistory();
    const modal = useModal();
    const user = useUser();

    const path = props.path
    const value = props.value
    const id = path === 'id' && value ? value : null;
    const map = path === 'map' && value ? value : null;

    const getTableTimes = async (title, offset) => {
        try {
            let {data} = await axios.get(`${AJAX}records/${title}${offset ? '?id=' + offset : ''}`)
            return data
        } catch (error) {
            setData('There was an error looking up the map.')
        }
    }

    const getMoreData = async (page) => {
        try {
            let times = await getTableTimes(title, (page-1)*20)
            setData(old => insertToArray(old, page-1, splitArray(formatTimesForList(times))))
        } catch (error) {
        }
    }

    // Initial page load/loading new title
    const loadTitle = useCallback(async (t) => {
        let title = tMap[t] ? t : 'wrs';

        try {
            let times = await getTableTimes(title)
            setTitle(title)
            setData(splitArray(formatTimesForList(times)))
        } catch (error) {
        }
    }, [])

    const loadTitleAgain = (clickedTitle) => {
        let currentTitle = path;
        if (clickedTitle === currentTitle) {
            loadTitle(clickedTitle)
        }
    }

    const titleDropDownOptions = [
        [ tMap["wrs"], () => { history.push(`/records/wrs`); loadTitleAgain('wrs') } ],
        [ tMap["server"], () => { history.push(`/records/server`); loadTitleAgain('server') } ],
        [ tMap["oldest"], () => { history.push(`/records/oldest`); loadTitleAgain('oldest') } ],
        [ tMap["longest"], () => { history.push(`/records/longest`); loadTitleAgain('longest') } ],
        [ tMap["shortest"], () => { history.push(`/records/shortest`); loadTitleAgain('shortest') } ]
    ]

    // const titleDropDownOptions = ['wrs', 'server', 'oldest', 'longest', 'shortest'].map(e => [tMap[e], loadTitle(e, true)])

    const submitHandler = async (map) => {
        let m = map.replace(/[^0-9a-zA-Z_-]/g, '')
        if (m.length > 0) {
            mapSearch(m)
        }
    }

    const mapSearch = async (map) => {
        try {
            let {data: times} = await axios.get(`${AJAX}records/map/${map}`);
            if (Array.isArray(times) && times.length > 0) {
                setTitle(`Times found on ${times[0].map}`)
                setColumnNames(["Player", "Date", "Time", ""])
                setData([formatTimesForList(times, true)])
            } else {
                setTitle(`Browse records`)
                setData('Found no map matching your search.')
            }
        } catch (error) {
            setData('There was an error looking up the map.')
            return error;
        }
    }

    useEffect(() => {
        if (map) {
            mapSearch(map)
        } else {
            loadTitle(path)
        }
    }, [loadTitle, loaded, map, path])

    useEffect(() => {
        if (modal && id && !loaded) {
            setLoaded(true)
            modal && modal.setModal({displayRunId: id})
        }
    }, [modal, id, loaded])

    return (
        <React.Fragment>
            <Helmet>
                <title>Records</title>
                <meta name="description" content="Bunnyhopping records" />
            </Helmet>
            <div className={styles.container}>
                <div>
                    <div className={styles.search_wrapper}>
                        <Searchbox 
                            placeholder="Search map..." 
                            submit={submitHandler} 
                            click={() => loadTitle(null, true)} 
                        />
                    </div>
                    <div className={styles.table_wrapper}>
                        <List 
                            title={title}
                            titleDropDownOptions={titleDropDownOptions}
                            columnNames={columnNames} 
                            data={data}
                            getMoreData={getMoreData}
                            records={true}
                            //tableClick={onTableClickHandler}
                        />
                        {user && (user.perms >= 10) && <p 
                            className={styles.more_button}
                            onClick={() => modal.setModal(<ManualRecord />)}>
                            Add record
                        </p>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Records;
