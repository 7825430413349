import React from "react";
import { Link } from 'react-router-dom'

import styles from "./bottom.module.css";
import { openInNewTab } from '../../shared/funcs'

const Bottom = (props) => {

    const e = () => {
        let v = 'source'
        v += 'jump'
        v += '@proton'
        v += 'mail.com'
        openInNewTab('mailto:'+v)
    }

    return (
        <footer>
            <div className={styles.container}>
                <div className={styles.links}>
                    <ul className={styles.ul}>
                        <li className={styles.li} onClick={() => openInNewTab('https://www.youtube.com/watch?v=_0g4yq3eEUo&list=PLRpPuTzNftfjj4cIeyQ8JkSn855XZN56R')}>
                            How to bunnyhop
                        </li>
                        <li className={styles.li} onClick={() => openInNewTab('https://docs.google.com/forms/d/e/1FAIpQLSd15Ev7G4JnyrecP6tvkmGOE8ovbRJlX5heRGizFa3dWOd9aA/viewform')}>
                            Submit a run
                        </li>
                        <li className={styles.li} onClick={() => openInNewTab('https://docs.google.com/forms/d/1eJ3D-4cPWYP2zEFF6rbBzPxxonUj33HBuLMxCLOWz1I/edit')}>
                            Submit a short run
                        </li>
                    </ul>
                    <ul className={styles.ul}>
                        <li>
                            <Link to="/converter">Steam ID Converter</Link>
                        </li>
                        <li>
                            <Link to="/api">API</Link>
                        </li>
                        <li className={styles.li}>
                            <p onClick={e}>Contact</p>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Bottom;