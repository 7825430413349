import React, { useState } from 'react';
import axios from 'axios'

import styles from './settings.module.css'
import { namesLong } from '../countries'
import { namesShort } from '../countries'
import { AJAX } from '../config'

const Settings = (props) => {

    const pInfo = props.pInfo;

    const [alias, setAlias] = useState(pInfo.alias || null)
    const [mouse, setMouse] = useState(pInfo.mouse || null)
    const [dpi, setDpi] = useState(pInfo.dpi || null)
    const [sens, setSens] = useState(pInfo.sens || null)
    const [mousePad, setMousePad] = useState(pInfo.mousePad || null)
    const [keyboard, setKeyboard] = useState(pInfo.keyboard || null)
    const [monitor, setMonitor] = useState(pInfo.monitor || null)
    const [aspect, setAspect] = useState(pInfo.aspect || null)
    const [country, setCountry] = useState(pInfo.country || null)
    const [saveButton, setSaveButton] = useState('Save')
    const [saved, setSaved] = useState(null)

    const makeDropDownList = () => {
        let options = [];
        
        pInfo.country && options.push(<option selected hidden>{namesShort[pInfo.country]}</option>)

        for (let country of Object.keys(namesLong)) {
            options.push(
                <option value={namesLong[country]} key={namesLong[country]}>{country}</option>
            )
        }

        return options
    }

    const countryChangeHandler = (event) => {
        setCountry(event.target.value)
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setSaved(true)
        if (!saved) {
            if (
                    alias || 
                    country ||
                    mouse || 
                    dpi ||
                    sens ||
                    mousePad ||
                    keyboard ||
                    monitor ||
                    aspect || 
                    country
                ) {
                setSaveButton('Saving...')
                try {
                    const config = {
                        url: AJAX + 'players/profile/update',
                        method: 'post',
                        withCredentials: true,
                        data: { 
                            alias, 
                            country, 
                            mouse,
                            dpi,
                            sens,
                            mousePad,
                            keyboard,
                            monitor,
                            aspect,
                            steamid: pInfo.steamid
                        }
                    };
                    await axios.request(config); 
                    setSaveButton('Saved')
                } catch (error) {
                    setSaveButton('Error saving')
                }
    
            }
        }
    }

    const makeTextInput = (name, desc, setInputState, prefilledValue) => {
        return (
            <div className={styles.option}>
                {name} <input 
                    type="text" 
                    placeholder={desc} 
                    className={styles.box} 
                    maxLength="64" 
                    onChange={(event) => setInputState(event.target.value)}
                    value={prefilledValue || ''}
                    />
            </div>
        )
    }
    
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.options}>
                    {makeTextInput('Name', 'Enter a new alias...', setAlias, alias)}
                    <div className={styles.option}>
                    <label htmlFor="countries">Country</label>
                        <select 
                            name="countries" 
                            id="countries" 
                            className={styles.dropdown} 
                            onChange={countryChangeHandler}>
                            <option value={country} hidden>Select your country</option>
                            {makeDropDownList()}
                        </select> 
                    </div>
                    {makeTextInput('Mouse', 'Your mouse', setMouse, mouse)}
                    {makeTextInput('DPI', 'Mouse DPI setting', setDpi, dpi)}
                    {makeTextInput('Sensitivity', 'In-game mouse sensitivity', setSens, sens)}
                    {makeTextInput('Mouse pad', 'Your mouse pad', setMousePad, mousePad)}
                    {makeTextInput('Keyboard', 'Your keyboard', setKeyboard, keyboard)}
                    {makeTextInput('Monitor', 'Your monitor', setMonitor, monitor)}
                    {makeTextInput('Aspect', 'Monitor aspect ratio', setAspect, aspect)}
                    <div className={styles.save} onClick={onSubmitHandler}>
                        <div className={styles.button}>{saveButton}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Settings