import React, { useState } from 'react';
import axios from 'axios'

import styles from './settings.module.css'
import { AJAX } from '../config'

const ChangeTier = (props) => {

    const [tier, setTier] = useState(null)
    const [saveButton, setSaveButton] = useState('Save')

    const makeDropDownList = () => {
        let options = [];

        for (let i = 0; i < 10; i++) {
            options.push(<option value={i+1} key={i}>{i+1}</option>)
        }

        return options
    }

    const tierChangeHandler = (event) => {
        setTier(event.target.value)
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (tier) {
            setSaveButton('Saving...')

            try {
                const config = {
                    url: AJAX + 'admin/changetier',
                    method: 'post',
                    withCredentials: true,
                    data: { tier: tier, map: props.map }
                };
                await axios.request(config); 
                setSaveButton('Saved')
            } catch (error) {
                setSaveButton('Error saving')
            }

        }
    }
    
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.options}>
                    <div className={styles.option}>
                    <label htmlFor="tier">New tier</label>
                        <select 
                            name="tier" 
                            id="tier" 
                            className={styles.dropdown} 
                            onChange={tierChangeHandler}>
                            <option value={tier} hidden>Select</option>
                            {makeDropDownList()}
                        </select> 
                    </div>
                    <div className={styles.save} onClick={onSubmitHandler}>
                        <div className={styles.button}>{saveButton}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ChangeTier