import React, { useState, useContext } from 'react';

import useMountEffect from './components/hooks/mount'

const ModalContext = React.createContext()

export const useModal = () => {
    return useContext(ModalContext)
}

export const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState(null)

    const setModalContent = (content) => {
        setModal({
            active: content,
            setModal: setModalContent
        })
    }

    useMountEffect(() => setModalContent(null))

    return (
        <ModalContext.Provider value={modal}>
            {children}
        </ModalContext.Provider>
    )
}