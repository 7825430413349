import React, {useState} from 'react';
import axios from 'axios'

import styles from './record.module.css'
import Spinner from "../spinner";
import { AJAX } from '../config'

const TextBox = (props) => {
    const {config} = props
    const [text, setText] = useState(null)
    const [content, setContent] = useState(null)

    const onChangeHandler = (event) => {
        setText(event.target.value)
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (text) {
            setContent(<span className={styles.center}><Spinner /></span>)
            const request = {
                url: `${AJAX}${config.apiEndpoint}`,
                method: 'post',
                withCredentials: true,
                data: { id: config.apiId, reason: text }
            }
            try {
                await axios.request(request)
                setContent( <p className={styles.center}>{config.successMsg}</p> )
            } catch (error) {
                setContent( <p className={styles.center}>{config.failMsg}</p> )
            }

        }
    }

    
    const i = <React.Fragment>
        <div style={{color: 'white'}}>{config.title}</div>
        <div>
            <textarea 
                className={styles.textarea} 
                rows="3" cols="30" 
                maxLength="148"
                placeholder="Reason..."
                onChange={onChangeHandler}
                onSubmit={onSubmitHandler}
                >
            </textarea>
        </div>
        <div style={{color: 'white'}} onClick={onSubmitHandler}>
            {config.bottomMsg}
        </div>
    </React.Fragment>


    return (<div className={styles.container_inv}>{content || i}</div>)
}
export default TextBox