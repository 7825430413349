const titles = {
    wrs: 'Latest world records',
    server: 'Latest server records',
    oldest: 'Oldest world records',
    longest: 'Longest world records',
    shortest: 'Shortest world records',
    top: 'Top players',
    records: 'Most world records'
}

export default titles