import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet-async";

import Flags from '../../../../shared/flags-list'
import List from "../../../../shared/list/list";
import { AJAX } from "../../../../shared/config";
import useMountEffect from "../../../../hooks/mount";
import styles from './servers.module.css'
import Tooltip from '../../../../shared/tooltip'

const Servers = (props) => {
    const [data, setData] = useState(null)

    const formatData = (arr) => {
        return arr.map(e => [e.id, [
            <Flags country={e.country} key={e.id + 'a'} />,
            (' ' + e.hostname)], 
            <Tooltip
                item={<a href={"steam://connect/" + e.ip} style={{"color": "inherit"}}>{e.ip}</a>} 
                tooltip={ <p>Click to join server</p> }
            />, 
            e.whitelist ? 'Yes' : 'No'])
    }

    const loadServers = async () => {
        try {
            const {data: servers} = await axios.get(`${AJAX}servers`, {withCredentials: true})
            setData([formatData(servers)])
        } catch (error) {
            setData('There was an error loading the server list.')
        }
    }

    const initialLoad = () => {
        loadServers()
    }

    useMountEffect(initialLoad)

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Servers</title>
                <meta name="description" content="Servers connected to SourceJump" />
            </Helmet>
            <List 
                title={'Servers connected to SourceJump'}
                columnNames={['Server', 'IP', 'Whitelist']} 
                data={data}
            />
        </div>
    )
}
export default Servers