import React from "react";

import styles from './tooltip.module.css'

const Tooltip = (props) => {
    return (
        <span className={styles.tooltip}>{props.item}
            <span className={styles.tooltiptext}>{props.tooltip}</span>
        </span>
    );
};
export default Tooltip;
