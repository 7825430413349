import React, { useState, useRef } from "react";

import styles from "./searchbox.module.css";

//import { ReactComponent as SearchIcon } from "../../assets/flags/ar.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";

const SearchBox = (props) => {
    const [text, setText] = useState(null)
    const inputRef = useRef()

    const onChangeHandler = (event) => {
        setText(event.target.value);
        if (props.onChangeHandler) {
        }
        props.onChangeHandler && props.onChangeHandler(event);
    }

    const onSubmitHandler = (event) => {
        event.preventDefault();
        if (text) {
            props.submit(text)
            setText(null)
            inputRef.current.value = null
        }
    }

    return (
        <div className={styles.search_box}>
            <form onSubmit={onSubmitHandler}>
                <input ref={inputRef} type="text" placeholder={props.placeholder} onChange={onChangeHandler} />
                {!props.disableSearchIcon && <SearchIcon className={styles.search_icon} onClick={onSubmitHandler} />}
            </form>
        </div>
    )
}

export default SearchBox;
