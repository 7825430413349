import React, { useState, useContext } from 'react';
import axios from 'axios'

import useMountEffect from './components/hooks/mount'
import { isObjEmpty } from './components/shared/funcs'
import { AJAX } from './components/shared/config'

const UserContext = React.createContext()

export const useUser = () => {
    return useContext(UserContext)
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null)

    const load = () => {
        checkSession()
    }

    const checkSession = async () => {
        try {
            let i = await axios.get(AJAX + 'auth/session', {withCredentials: true})
            if (i.data && !isObjEmpty(i.data)) {
                setUser(i.data)
            }
        } catch (error) {
            console.log('Error fetching session:', error)
        }

    }

    useMountEffect(load)

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    )
}