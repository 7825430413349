import React, { useState } from 'react';
import axios from 'axios'

import Spinner from "../../../../shared/spinner";
import styles from './home.module.css'
import useMountEffect from '../../../../hooks/mount'
import { AJAX } from "../../../../shared/config";

const Home = (props) => {

    const [video, setVideo] = useState(<div><Spinner /></div>)

    const getLatestVid = async () => {
        try {
            let {data} = await axios.get(`${AJAX}main/latest`)
            setVideo(
                <div className={styles.video}>
                    <iframe 
                            title="Latest SourceJump video" 
                            width="480" 
                            height="270" 
                            src={`https://www.youtube.com/embed/${data}`}
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                    </iframe>
                </div>
            )
        } catch (error) {
            setVideo (
                <div>
                    There was a problem getting the latest video.
                </div>
            )
        }
    }

    const load = () => {
        getLatestVid()
    }

    useMountEffect(load)

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.section}>
                    <h1 className={styles.title}>What is SourceJump?</h1>
                    <p>
                        The global bunnyhopping community for Counter-Strike: Source.
                    </p>
                    <p className={styles.p}>
                        SourceJump also publishes exceptional runs on the community driven YouTube channel, as well as manage
                        a collective database of records done on dedicated bhop maps in CS:S, which you may access
                        through the links above.
                    </p>
                    <h1 className={styles.title}>Latest video</h1>
                    {video}
                </div>
                
                {/* <div className={styles.section}>
                    <h1 className={styles.title}>Latest video</h1>
                        <iframe 
                            title="Latest SourceJump video" 
                            width="480" 
                            height="270" 
                            src="https://www.youtube.com/embed/8o3yzYnfpWo" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                </div> */}
            </div>
        </React.Fragment>
    )
}

export default Home