import React, {useState} from "react";

import Tooltip from '../tooltip'
import styles from './record.module.css'
import { HOME } from '../config'

const RecordId = (props) => {
    const [idText, setIdText] = useState('Copy run id')

    const idClickHandler = () => {
        setIdText('Copied')
        navigator.clipboard.writeText(`${HOME}records/id/${props.id}`)
    }

    return (
        <span className={styles.map_id_container} onClick={idClickHandler}>
            <Tooltip item={props.id} tooltip={idText} />
        </span>
    );
};
export default RecordId;
