import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { Helmet } from "react-helmet-async";

import styles from './players.module.css'
import Searchbox from "../../../../shared/searchbox";
import List from "../../../../shared/list/list";
import TextBox from "../../../../shared/modal/textbox";
import Spinner from "../../../../shared/spinner";
import Flags from "../../../../shared/flags-list";
import { namesShort } from "../../../../shared/countries";
import { DefaultAvatar } from "../../../../shared/image-imports";
import { formatTimesForList, splitArray, openInNewTab, getSteamLink } from "../../../../shared/funcs";
import { AJAX } from "../../../../shared/config";
import tMap from "../../../../shared/list/tableViewMap";
import { useModal } from '../../../../../ModalContext'
import { useUser } from '../../../../../UserContext'
import Settings from '../../../../shared/modal/settings'
import Tooltip from '../../../../shared/tooltip'

export const signOut = async () => {
    try {
        await axios.get(AJAX + 'auth/signout', {withCredentials: true});
        window.location.reload();
    } catch (error) {
    }
};

const Players = (props) => {
    const [content, setContent] = useState(null)
    const [setup, setSetup] = useState(null)
    const [data, setData] = useState(null)
    const [title, setTitle] = useState(null)
    const [steamid, setSteamid] = useState(null)
    const [playerFound, setPlayerFound] = useState(null)
    const [playerInfo, setPlayerInfo] = useState(null)
    const [hasSetup, setHasSetup] = useState(null)
    const [lastParamSearch, setLastParamSearch] = useState(null)

    const history = useHistory()
    const modal = useModal();
    const user = useUser();

    const formatPlayerPage = (pInfo) => {
        let country = pInfo.country

        return (
            <React.Fragment>
                <div className={styles.left}>
                    <div className={styles.title1}>Player</div>
                    {pInfo.alias || pInfo.name}
                    <div className={styles.title}>Country</div>
                    {country ? (
                        <React.Fragment>
                            <Flags country={pInfo.country} />{` ${namesShort[country]}`}
                        </React.Fragment>
                    ) : 'Unknown'}
                    <div className={`${styles.title}`}>Steam</div>
                        <span onClick={() => openInNewTab(getSteamLink(pInfo.steamid))} className={'link'}>Profile</span>
                </div>
                <div className={styles.mid}>
                    <div className={styles.title1}>Global rank</div>
                    {pInfo.points_auto === 0 ? 'Unranked' : pInfo.rank_auto}
                    <div className={styles.title}>World records</div>
                    {pInfo.records_total || 0}
                </div>
                <div className={styles.right}>
                    <img src={pInfo.avatar ? pInfo.avatar : DefaultAvatar} alt="" className={styles.avatar} />
                </div>
                
            </React.Fragment>
        )
    }


    const formatPlayerPageSetup = (pInfo) => {

        if (
            pInfo.mouse || 
            pInfo.dpi || 
            pInfo.monitor || 
            pInfo.keyboard || 
            pInfo.mousePad || 
            pInfo.sens || 
            pInfo.aspect
            ) {
                setHasSetup(true);
            }

        return (
            <React.Fragment>
                <div className={styles.setup_container}>
                    <span className={styles.setup_left}>
                        {pInfo.mouse && <span> <div className={styles.title1}>Mouse</div> {pInfo.mouse} </span> }
                        {pInfo.dpi && <span> <div className={styles.title}>DPI</div> {pInfo.dpi} </span> }
                        {pInfo.monitor && <span> <div className={styles.title}>Monitor</div> {pInfo.monitor} </span> }
                        {pInfo.keyboard && <span> <div className={styles.title}>Keyboard</div> {pInfo.keyboard} </span> }
                    </span>
                    <div className={styles.setup_right}>
                        {pInfo.mousePad && <span> <div className={styles.title1}>Mouse pad</div> {pInfo.mousePad} </span> }
                        {pInfo.sens && <span> <div className={styles.title}>Sensitivity</div> {pInfo.sens} </span> }
                        {pInfo.aspect && <span> <div className={styles.title}>Aspect ratio</div> {pInfo.aspect} </span>  }
                    </div>
                </div>
                
            </React.Fragment>
        )
    }

    const titleDropDownOptions = [
        [ tMap["wrs"], () => loadTitle('wrs') ],
        [ tMap["server"], () => loadTitle('server') ]
    ]


    const formatListMultiplePlayers = (arr) => {
        let r = arr.map(e => {
            const name = e.alias || e.name;
            return [
                e.id,
                e.country ? [<Flags country={e.country} key={e.id+'_a'} />, ` ${name}`] : name,
                e.steamid,
                e.records_total,
                {steamidClick: e.steamid}
            ]
        })
        return [r]
    }

    const loadTitle = async (title) => {
        try {
            const {data} = await axios.get(`${AJAX}players/records/${title}/${steamid}`)
            setTitle(title)
            if (data.length > 0) {
                setData(splitArray(formatTimesForList(data.sort((a, b) => (a.date < b.date) ? 1 : -1), null, true)))
            } else {
                setData('Found no records.')
            }
        } catch (error) {
        }
    }

    const submitHandler = useCallback(async (searchTerm) => {
        setData(null)
        setPlayerFound(null)
        setHasSetup(false)
        try {
            setContent( <Spinner /> )
            const {data} = await axios.get(`${AJAX}players/search/${searchTerm}`)
            const l = data.info.length
            if (l > 1) {
                history.push(`/players`)
                setContent(
                    <List
                        title={`Found ${data.info.length} players`} 
                        columnNames={["Player", "Steam ID", "World records"]} 
                        data={formatListMultiplePlayers(data.info)}
                        centerText={true}
                        steamid={true}
                    />);
                (data !== null) && setData(null);
            } else if (l === 0) {
                history.push(`/players`)
                setContent( <p className={styles.text}>Your search yielded no results.</p> );
                (data !== null) && setData(null);
            } else {
                setPlayerFound(data.info[0])
                setSteamid(data.info[0].steamid)
                setContent(formatPlayerPage(data.info[0]))
                setSetup(formatPlayerPageSetup(data.info[0]))
                setPlayerInfo(data.info[0])
                setData(' ')
                setTitle('Browse player records')
            }
        } catch (error) {
            setContent( <p className={styles.text}>There was an error looking up the player.</p> );
        }
    }, [history])

    useEffect(() => {
        setLastParamSearch(props.steamid)
        if (props.steamid && (props.steamid !== lastParamSearch)) {
            submitHandler(props.steamid)
        }
    }, [props.steamid, lastParamSearch, submitHandler])
        
    let adminOptions = null;
    let badges = null;
    const adminStyles = `${styles.badges} ${styles.admin}`

    if (playerInfo && playerFound) {
        const pName = playerInfo.alias || playerInfo.name;
        if (user) {
            let a = [];
            const banConfig = {
                title: `Banning ${pName} (${playerInfo.steamid})`,
                successMsg: `The player has been banned, and all records invalidated.`,
                failMsg: 'There was an error.',
                bottomMsg: <span className={styles.button}>Ban</span>,
                apiEndpoint: `admin/ban`,
                apiId: playerInfo.steamid
            }
    
            const unbanConfig = {
                title: `Unbanning ${pName} (${playerInfo.steamid})`,
                successMsg: `The player has been unbanned, and all records/points restored.`,
                failMsg: 'There was an error.',
                bottomMsg: <span className={styles.button}>Unban</span>,
                apiEndpoint: `admin/unban`,
                apiId: playerInfo.steamid
            }
    
            const lockConfig = {
                title: `Locking ${pName}'s profile`,
                successMsg: `Successfully locked the players profile.`,
                failMsg: 'There was an error.',
                bottomMsg: <span className={styles.button}>Lock</span>,
                apiEndpoint: `admin/lock`,
                apiId: playerInfo.steamid
            }
    
            const unlockConfig = {
                title: `Unlocking ${pName}'s profile`,
                successMsg: `Successfully unlocked the players profile.`,
                failMsg: 'There was an error.',
                bottomMsg: <span className={styles.button}>Unlock</span>,
                apiEndpoint: `admin/unlock`,
                apiId: playerInfo.steamid
            }
    
            let u = (user.steamid === playerInfo.steamid);
            let p = (user.perms >= 50);
    
            p && !playerInfo.banned && a.push(<div className={adminStyles} onClick={() => modal.setModal(<TextBox config={banConfig} />)} key={'a1'}>Ban player</div>);
            p && playerInfo.banned && a.push(<div className={adminStyles} onClick={() => modal.setModal(<TextBox config={unbanConfig} />)} key={'a1'}>Unban player</div>);
            p && a.push(<div className={adminStyles} onClick={() => modal.setModal(<TextBox config={playerInfo.locked ? unlockConfig : lockConfig} />)} key={'a2'}>{playerInfo.locked ? 'Unl' : 'L'}ock</div>);
            (u || p) && a.push( <div className={adminStyles} onClick={() => modal.setModal(<Settings pInfo={playerInfo} />)} key={'a3'}>Edit profile</div>);
            u && a.push(<div className={adminStyles} onClick={signOut} key={'a4'}>Sign out</div>);
    
            (a.length >= 1) && (adminOptions = ( 
                    <div>
                        <div className={styles.admin_container}>
                            <div>
                                {a}
                            </div>
                        </div>
                    </div>
                ));
        }
        
        playerInfo.banned && (badges = ( 
            <div>
                <div className={`${styles.admin_container} ${styles.badges_container}`}>
                    <div>
                        <Tooltip item={ <div className={styles.badges} style={{backgroundColor: 'rgb(221, 43, 88)'}}>Banned</div> } tooltip={'This player is banned for cheating'} />
                    </div>
                </div>
            </div>
        ));
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Players</title>
                <meta name="description" content="Players" />
            </Helmet>
            <div className={styles.container}>
                <Searchbox 
                placeholder="Name, Steam ID..."
                submit={submitHandler} 
                />
            </div>
            <div className={`${(data !== null) && styles.content}`}>
                <div className={styles.container}>
                    {content}
                </div>
                {playerFound && hasSetup && <div className={styles.container} style={{"marginTop": "10px" }}>{setup}</div>}
                    {badges}
                    {playerFound && adminOptions}

            </div>
            {data && <div className={styles.table_wrapper}>
                <List 
                    title={title}
                    columnNames={["Map", "Date", 'Time', '']} 
                    data={data} 
                    titleDropDownOptions={titleDropDownOptions}
                    maxPages={data.length}
                    records={true}
                />
            </div>}
        </React.Fragment>
    );
};

export default Players;
