import React, { useState } from 'react';
import axios from 'axios'

import styles from './settings.module.css'
import { AJAX } from '../config'

const VideoId = (props) => {

    const [videoId, setVideoId] = useState(null)
    const [saveButton, setSaveButton] = useState('Save')

    const nameChangeHandler = (event) => {
        setVideoId(event.target.value)
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (videoId) {
            setSaveButton('Saving...')

            try {
                const config = {
                    url: AJAX + 'admin/videoid',
                    method: 'post',
                    withCredentials: true,
                    data: { videoId: videoId, runId: props.runId }
                };
                await axios.request(config); 
                setSaveButton('Saved')
            } catch (error) {
                setSaveButton('Error saving')
            }

        }
    }
    
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.options}>
                    <div className={styles.option}>
                        Video ID <input 
                            type="text" 
                            placeholder={'YouTube ID or null'} 
                            className={styles.box} 
                            maxLength="32" 
                            onChange={nameChangeHandler}
                            />
                    </div>
                    <div className={styles.save} onClick={onSubmitHandler}>
                        <div className={styles.button}>{saveButton}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default VideoId