import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import Top from "./components/main/top/top";
import Middle from "./components/main/middle/middle";
import Bottom from "./components/main/bottom/bottom";
import Modal from './components/shared/modal/modal';
import { UserProvider } from './UserContext'
import { ModalProvider } from './ModalContext'

import "./App.css";

const App = () => {

    return (
        <BrowserRouter>
            <UserProvider>
                <ModalProvider>
                    <HelmetProvider>
                        <Modal />
                        <div className="container">
                            <Top />
                            <Middle />
                            <Bottom />
                        </div>
                    </HelmetProvider>
                </ModalProvider>
            </UserProvider>
        </BrowserRouter>
    );
};

export default App;
