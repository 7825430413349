import React, {useState} from "react";
import { useHistory } from 'react-router-dom';

import Flags from "../flags-list";
import styles from './record.module.css'
import RecordId from "./record-id";
import { colorizeWrDiff, openInNewTab } from '../funcs'
import Tooltip from '../tooltip'
import { useUser } from '../../../UserContext'
import TextBox from './textbox'
import ChangeTier from './changing-tier'
import ChangePoints from './changing-points'
import ChangeVideo from './editing-video'
import { useModal } from '../../../ModalContext'
import { YTLogo } from '../../shared/image-imports'

const Record = (props) => {
    const history = useHistory();
    const [invalidating, setInvalidating] = useState(null)
    const [changingTier, setChangingTier] = useState(null)
    const [changingPoints, setChangingPoints] = useState(null)
    const [editingVideo, setEditingVideo] = useState(null)
    const user = useUser();
    const modal = useModal();

    let time = props.time;

    const nameClick = () => {
        modal.setModal(null)
        history.push(`/players/${time.steamid}`)
    }

    const mapClick = () => {
        modal.setModal(null)
        history.push(`/records/map/${time.map}`)
    }

    const tierClick = () => {
        if (user && (user.perms >= 10)) {
            setChangingTier(true)
        }
    }

    const pointsClick = () => {
        if (user && (user.perms >= 10)) {
            setChangingPoints(true)
        }
    }

    const editVideo = () => {
        if (user && (user.perms >= 10)) {
            setEditingVideo(true)
        }
    }

    const banned = time.banned ? 'Player is banned.' : null
    const bad_zones = time.bad_zones ? 'Improper map zones.' : null
    const invalidReason = banned || time.invalid || bad_zones || null;
    
    const v = !time.invalid ? true : false
    const config = {
        title: `${v ? 'Inv' : 'V'}alidating ${time.map} by ${time.name}`,
        successMsg: `Run ${v ? 'Inv' : 'V'}alidated.`,
        failMsg: 'There was an error handling the request.',
        bottomMsg: <span className={styles.admin}>{v ? 'Inv' : 'V'}alidate</span>,
        apiEndpoint: `admin/${v ? 'in' : ''}validate`,
        apiId: time.id
    }

    const avatarAndName = (
        <React.Fragment>
            <Tooltip 
                item={<img className={styles.avatar} onClick={nameClick} src={time.avatar} alt=""/>}
                tooltip={'Visit player profile'}
            />
            <span>
                {time.country ? <Flags country={time.country} /> : null} <span className={styles.player_name} onClick={nameClick}>{time.name}</span>
            </span>
        </React.Fragment>
    )

    const record = <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.map_container}>
                            {user && (user.perms >= 10) && <Tooltip
                                item={<span  className={styles.map}
                                onClick={() => setInvalidating(true)}>{!time.invalid ? 'X' : 'V'}</span>} 
                                tooltip={ !time.invalid ? `Invalidate run` : 'Validate run' }
                            />}
                            <Tooltip
                                item={<span className={styles.map} onClick={mapClick}>{time.map}</span>} 
                                tooltip={ <p>Browse all times on map</p> }
                            />
                            <Tooltip
                                item={<span className={`${styles.map} ${styles.t}`} onClick={tierClick}>T{time.tier}</span>} 
                                tooltip={ `Tier ${time.tier}` }
                            />
                            <Tooltip
                                item={<span className={`${styles.map} ${styles.t}`} onClick={pointsClick}>{time.points}P</span>} 
                                tooltip={ `${time.points === 0 ? `This map does not add to any ranking metrics` : `World record on this map is worth ${time.points} points`} ` }
                            />
                            <RecordId id={time.id} />
                        </div>
                        <div className={styles.middle_avatar}>{avatarAndName}</div>
                        <div className={styles.title}>Time</div>
                            <span>{time.time} {colorizeWrDiff(time.wrDif)}</span>
                        <div className={styles.title}>Run details</div>
                            <span>Sync: {time.sync || 'N/A'} Strafes: {time.strafes || 'N/A'} Jumps: {time.jumps || 'N/A'}</span>
                            <div>Date: {time.date || 'N/A'}</div>
                        <div className={styles.title}>Server</div>
                            <span>{time.hostname}</span>
                            
                        <div className={styles.vidCenter}>
                            {time.video ? <Tooltip
                                item={
                                    <div onClick={() => openInNewTab("https://youtu.be/" + time.video)} className={styles.vid} style={{ "marginRight": "5px" }}>
                                        <img src={YTLogo} alt="" className={styles.yt} />
                                        <p style={{"marginTop": "2px" }}>
                                            Video
                                        </p>
                                    </div>
                                }
                                tooltip={ `View this run on YouTube` }
                            /> : null }
                            {user && (user.perms >= 10) && <span onClick={editVideo} className={styles.editVid}>
                                <p style={{"marginTop": "2px", "marginLeft": "2px", "color": "rgb(255, 255, 255)" }}>
                                    Edit video
                                </p>
                            </span>}
                        </div>
                        
                        {invalidReason && <div>
                            <div className={`${styles.title} ${styles.admin}`} style={{cursor: 'text', marginTop: '10px'}}>Invalid time: {invalidReason}</div>
                        </div>}
                    </div>
                    <div className={styles.middle}></div>
                    <div className={styles.right}>{avatarAndName}</div>
                </div>

        let rendering;

        if (invalidating) {
            rendering = <TextBox config={config} />
        } else if (changingTier) {
            rendering = <ChangeTier map={time.map} />
        } else if (changingPoints) {
            rendering = <ChangePoints map={time.map} />
        } else if (editingVideo) {
            rendering = <ChangeVideo runId={time.id} />
        } else {
            rendering = record
        }

    return rendering
};
export default Record;
