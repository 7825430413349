import React, { useState } from 'react';
import SteamID from 'steamid'
import { Helmet } from "react-helmet-async";

import SearchBox from '../../../../shared/searchbox'

import styles from './id-converter.module.css'

const SteamIdConverter = (props) => {
    const [valid, setValid] = useState(null)

    let def = <span style={{color: '0, 0, 0'}}>Invalid Steam ID</span>

    const onChangeHandler = (event) => {
        try {
            let s = new SteamID(event.target.value)
            setValid({
                id: s.getSteam2RenderedID(),
                id3: s.getSteam3RenderedID(),
                id64: s.getSteamID64()
            })
        } catch (e) {
            valid && setValid(false)
        }
    }

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Steam ID converter</title>
                <meta name="description" content="Convert Steam ids" />
            </Helmet>
            <div className={styles.content}>
                <h1>Steam ID converter</h1>
                <p style={{marginTop: '10px', marginBottom: '20px'}}>Convert between different representations of a Steam ID.</p>
                <SearchBox placeholder={'Steam ID, ID64, ID3...'} disableSearchIcon={true} onChangeHandler={onChangeHandler} submit={() => {return}}/>
                <div className={styles.steamids}>                    
                    <span className={styles.steamid}><p>Steam ID:</p>{(valid && valid.id) || def}</span>
                    <span className={styles.steamid}><p>Steam ID3:</p>{(valid && valid.id3) || def}</span>
                    <span className={styles.steamid}><p>Steam ID64:</p>{(valid && valid.id64) || def}</span>
                </div>
            </div>
        </div>
    )
}
export default SteamIdConverter