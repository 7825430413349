import React from 'react';

import Tooltip from './tooltip'

const Shorten = (props) => {
    let l = props.length || 16
    if (props.text.length > l) {
        return <Tooltip item={props.text.slice(0, l) + '...'} tooltip={props.text} />
    }
    return props.text

}
export default Shorten