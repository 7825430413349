import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Records from "./pages/records/records";
import Leaderboards from "./pages/leaderboards/leaderboards";
import Players from "./pages/players/players";
import Home from "./pages/home/home";
import Community from "./pages/community/community";
import Servers from "./pages/servers/servers";
import styles from './middle.module.css'
import { AJAX, DOMAIN } from '../../shared/config'
import SteamIdConverter from "./pages/id-converter/id-converter";
import API from "./pages/api/api";
import { isXmas } from '../../shared/funcs'

const Middle = (props) => {
    const link = `
        https://steamcommunity.com/openid/login
        ?openid.ns=http://specs.openid.net/auth/2.0
        &openid.mode=checkid_setup
        &openid.return_to=${AJAX}auth/verify
        &openid.realm=${DOMAIN}
        &openid.ns.sreg=http://openid.net/extensions/sreg/1.1
        &openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select
        &openid.identity=http://specs.openid.net/auth/2.0/identifier_select
    `
    let routes = (
            <Switch>
                {/* <Route exact path="/records" component={Records} modalState={props.modalState} /> */}
                {/* <Route exact path="/records/map/:map" component={Records} /> */}
                <Route
                    path="/records/:path?/:value?"
                    render={({ match }) => <Records path={match.params.path} value={match.params.value} />}
                />
                {/* <Route exact path="/records/id/:id" component={Records} />
                <Route path="/records/" component={Records} >
                    <Redirect to="/records" />
                </Route> */}

                <Route exact path="/leaderboards" component={Leaderboards} />

                {/* <Route exact path="/players/" component={Players} />
                <Route exact path="/players/:steamid" component={Players} />
                <Route path="/players/" component={Players} >
                    <Redirect to="/players" />
                </Route> */}

                <Route
                    path="/players/:steamid?"
                    render={({ match }) => <Players steamid={match.params.steamid} />}
                />

                <Route
                    exact
                    path="/community"
                    render={() => <Community />}
                />

                <Route
                    exact
                    path="/servers"
                    render={() => <Servers />}
                />

                <Route
                    exact
                    path="/converter"
                    render={() => <SteamIdConverter />}
                />

                <Route
                    exact
                    path="/api"
                    render={() => <API />}
                />

                <Route
                    exact path="/signin"
                    render={() => {
                        window.location.replace(link.replace(/\s+/g, ''))
                        return <div className={styles.container}><h1>Redirecting to Steam...</h1></div>
                    }}
                />

                <Route
                    exact path="/"
                    render={() => <Home />}
                />

                <Redirect
                    to={{
                        pathname: "/"
                    }}
                />

            </Switch>
    );
    return (
        <React.Fragment>
            <Helmet>
                <title>SourceJump</title>
                <meta name="description" content="Global bunnyhop community" />
            </Helmet>
            <main className={`${styles.main} ${isXmas ? styles.xmas : ''}`}>{routes}</main>
        </React.Fragment>
    );
};
export default Middle;
