import React, {useState} from 'react';
import axios from 'axios'
import { Helmet } from "react-helmet-async";

import List from "../../../../shared/list/list";
import useMountEffect from "../../../../hooks/mount";
import styles from './leaderboards.module.css'
import Flags from '../../../../shared/flags-list'
import tMap from "../../../../shared/list/tableViewMap";
import { splitArray } from "../../../../shared/funcs";
import { AJAX } from "../../../../shared/config";

const Leaderboards = (props) => {
    let [title, setTitle] = useState(null)
    let [data, setData] = useState(null)
    let [columnNames, setColumnNames] = useState(["Rank", "Player", "Points"])


    const styleRank = (rank, str, key) => {
        if (rank < 4) {
            return <span className={styles['rank_' + rank]} key={key + 'a'}>{str}</span>
        } else if (rank < 11) {
            return <span className={styles.rank_10up} key={key + 'b'}>{str}</span>
        } else {
            return str
        }
    }

    const titleDropDownOptions = [
        [ tMap["top"], () => loadTitle('top', true) ],
        [ tMap["records"], () => loadTitle('records', true) ]
    ]

    let mapForList = (arr) => {
        return arr.map((player, index) => {
            let rank = index + 1
            return [
                player.id + 'A',
                '#' + rank,
                [
                    player.country ? [<Flags country={player.country} key={player.id + 'e'} />, ' '] : null,
                    styleRank(rank, player.name, player.id)
                ],
                player.points,
                {steamidClick: player.steamid}
            ]
        })
    }

    const loadTitle = async (title) => {
        try {
            const {data: players} = await axios.get(`${AJAX}leaders/${title}`)
            setTitle(title)
            setData(splitArray(mapForList(players)))
            setColumnNames(["Rank", "Player", `${title === 'top' ? 'Points' : 'Records'} `])
        } catch (error) {
            setData('There was an error loading the leaderboards.')
        }
    }

    const initialLoad = () => {
        loadTitle('top')
    }

    useMountEffect(initialLoad)

    return (
        <div className={styles.table_wrapper}>
            <Helmet>
                <title>Leaderboards</title>
                <meta name="description" content="Leaderboards" />
            </Helmet>
            {Array.isArray(data) ? <List 
                title={title}
                titleDropDownOptions={titleDropDownOptions}
                columnNames={columnNames} 
                data={data}
                maxPages={10}
                centerText={true}
                steamid={true}
                //tableClick={onTableClickHandler}
            /> : data} 
        </div>
    )
}
export default Leaderboards