import React from 'react'
import SteamID from 'steamid'

import Shorten from './shorten'
import Flags from './flags-list'
import styles from  './funcs.module.css'

export const colorizeWrDiff = (str, key, style) => {

    const s = str.toString();

    if (s[0] === '+') {
        return <span className={styles.red} key={key} style={style}>{s}</span>
    }

    return <span className={styles.green} key={key} style={style} >{s}</span>
}

export const formatTimesForList = (times, mapSearch, singlePlayerList) => {
    let s = times.map(t => {
        let timeWhole = t.time.toString().split('.');
        return [
            t.id,
            !singlePlayerList ? [
                t.country ? [<Flags country={t.country} key={t.id+'_a'} />, ' '] : null,
                <Shorten text={t.name} key={t.id + 'a'}/>
            ] : <Shorten text={t.map} key={t.id + 'e'}/>,
            mapSearch || singlePlayerList ? t.date : <Shorten text={t.map} key={t.id + 'b'}/>, 
            [   
                <span className={styles.time_whole} key={t.id+'c'}>{timeWhole[0] }</span>, 
                <span className={styles.time_decimal} key={t.id+'d'}>.{timeWhole[1] }</span>
            ],
            colorizeWrDiff(t.wrDif, t.id+'e', { float: "right", marginTop: "4px" })
        ]
        }
    )
    return s
}

export const formatTimesForPlayerList = (times) => {
    let s = times.map(t => {
        let timeWhole = t.time.toString().split('.');
        return [
            t.id,
            t.date,
            [   
                <span className={styles.time_whole} key={t.id+'c'}>{timeWhole[0] }</span>, 
                <span className={styles.time_decimal} key={t.id+'d'}>.{timeWhole[1] }</span>
            ],
            colorizeWrDiff(t.wrDif, t.id+'e', { float: "right", marginTop: "4px" })
        ]
        }
    )
    return s
}

export const isObjEmpty = (obj) => {
    for (let i in obj) return false;
    return true;
}

export const insertToArray = (originalArray, insertAt, arrayToInsert) => {
    let arr = originalArray;

    for (let i = 0; i < arrayToInsert.length; i++) {
        arr[insertAt + i] = arrayToInsert[i]
    }

    return arr
}

// Split array into multiple smaller arrays (arrSize = size per nested arr)
export const splitArray = (arr, arrSize = 20) => {
    let newArr = [];
    let pos = 0;
    let itemsAdded = arrSize-1;

    for (let item of arr) {
        !newArr[pos] && (newArr[pos] = [])
        newArr[pos].push(item)

        if (itemsAdded === 0) {
            itemsAdded = arrSize-1
            pos++
        } else {
            itemsAdded--
        }
    }

    return newArr
}

// Safely open new tab url
export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const getSteamLink = (steamid) => {
    return ('https://steamcommunity.com/profiles/' + new SteamID(steamid).getSteamID64());
}

export const isXmas = (() => {
    const date = new Date().getDate();
    const month = new Date().getMonth();

    if (date >= 15 && date <= 25 && month === 11) {
        return true
    } else {
        return false
    }
})();