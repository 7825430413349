import ad from '../../assets/images/flags/ad.png';
import ae from '../../assets/images/flags/ae.png';
import af from '../../assets/images/flags/af.png';
import ag from '../../assets/images/flags/ag.png';
import ai from '../../assets/images/flags/ai.png';
import al from '../../assets/images/flags/al.png';
import am from '../../assets/images/flags/am.png';
import ao from '../../assets/images/flags/ao.png';
import ar from '../../assets/images/flags/ar.png';
import asm from '../../assets/images/flags/asm.png';
import at from '../../assets/images/flags/at.png';
import au from '../../assets/images/flags/au.png';
import aw from '../../assets/images/flags/aw.png';
import ax from '../../assets/images/flags/ax.png';
import az from '../../assets/images/flags/az.png';
import ba from '../../assets/images/flags/ba.png';
import bb from '../../assets/images/flags/bb.png';
import bd from '../../assets/images/flags/bd.png';
import be from '../../assets/images/flags/be.png';
import bf from '../../assets/images/flags/bf.png';
import bg from '../../assets/images/flags/bg.png';
import bh from '../../assets/images/flags/bh.png';
import bi from '../../assets/images/flags/bi.png';
import bj from '../../assets/images/flags/bj.png';
import bm from '../../assets/images/flags/bm.png';
import bn from '../../assets/images/flags/bn.png';
import bo from '../../assets/images/flags/bo.png';
import bq from '../../assets/images/flags/bq.png';
import br from '../../assets/images/flags/br.png';
import bs from '../../assets/images/flags/bs.png';
import bt from '../../assets/images/flags/bt.png';
import bw from '../../assets/images/flags/bw.png';
import by from '../../assets/images/flags/by.png';
import bz from '../../assets/images/flags/bz.png';
import ca from '../../assets/images/flags/ca.png';
import cc from '../../assets/images/flags/cc.png';
import cd from '../../assets/images/flags/cd.png';
import cf from '../../assets/images/flags/cf.png';
import cg from '../../assets/images/flags/cg.png';
import ch from '../../assets/images/flags/ch.png';
import ci from '../../assets/images/flags/ci.png';
import ck from '../../assets/images/flags/ck.png';
import cl from '../../assets/images/flags/cl.png';
import cm from '../../assets/images/flags/cm.png';
import cn from '../../assets/images/flags/cn.png';
import co from '../../assets/images/flags/co.png';
import cr from '../../assets/images/flags/cr.png';
import cu from '../../assets/images/flags/cu.png';
import cv from '../../assets/images/flags/cv.png';
import cx from '../../assets/images/flags/cx.png';
import cy from '../../assets/images/flags/cy.png';
import cz from '../../assets/images/flags/cz.png';
import de from '../../assets/images/flags/de.png';
import dj from '../../assets/images/flags/dj.png';
import dk from '../../assets/images/flags/dk.png';
import dm from '../../assets/images/flags/dm.png';
import dom from '../../assets/images/flags/dom.png';
import dz from '../../assets/images/flags/dz.png';
import ec from '../../assets/images/flags/ec.png';
import ee from '../../assets/images/flags/ee.png';
import eg from '../../assets/images/flags/eg.png';
import en from '../../assets/images/flags/en.png';
import er from '../../assets/images/flags/er.png';
import es from '../../assets/images/flags/es.png';
import et from '../../assets/images/flags/et.png';
import eU from '../../assets/images/flags/eU.png';
import fi from '../../assets/images/flags/fi.png';
import fj from '../../assets/images/flags/fj.png';
import fk from '../../assets/images/flags/fk.png';
import fm from '../../assets/images/flags/fm.png';
import fo from '../../assets/images/flags/fo.png';
import fr from '../../assets/images/flags/fr.png';
import ga from '../../assets/images/flags/ga.png';
import gd from '../../assets/images/flags/gd.png';
import ge from '../../assets/images/flags/ge.png';
import gg from '../../assets/images/flags/gg.png';
import gh from '../../assets/images/flags/gh.png';
import gi from '../../assets/images/flags/gi.png';
import gl from '../../assets/images/flags/gl.png';
import gm from '../../assets/images/flags/gm.png';
import gn from '../../assets/images/flags/gn.png';
import gq from '../../assets/images/flags/gq.png';
import gr from '../../assets/images/flags/gr.png';
import gt from '../../assets/images/flags/gt.png';
import gu from '../../assets/images/flags/gu.png';
import hk from '../../assets/images/flags/hk.png';
import hn from '../../assets/images/flags/hn.png';
import hr from '../../assets/images/flags/hr.png';
import ht from '../../assets/images/flags/ht.png';
import hu from '../../assets/images/flags/hu.png';
import id from '../../assets/images/flags/id.png';
import ie from '../../assets/images/flags/ie.png';
import il from '../../assets/images/flags/il.png';
import im from '../../assets/images/flags/im.png';
import ind from '../../assets/images/flags/ind.png';
import iq from '../../assets/images/flags/iq.png';
import ir from '../../assets/images/flags/ir.png';
import is from '../../assets/images/flags/is.png';
import it from '../../assets/images/flags/it.png';
import je from '../../assets/images/flags/je.png';
import jm from '../../assets/images/flags/jm.png';
import jo from '../../assets/images/flags/jo.png';
import jp from '../../assets/images/flags/jp.png';
import ke from '../../assets/images/flags/ke.png';
import kg from '../../assets/images/flags/kg.png';
import kh from '../../assets/images/flags/kh.png';
import km from '../../assets/images/flags/km.png';
import kn from '../../assets/images/flags/kn.png';
import kr from '../../assets/images/flags/kr.png';
import kw from '../../assets/images/flags/kw.png';
import ky from '../../assets/images/flags/ky.png';
import kz from '../../assets/images/flags/kz.png';
import la from '../../assets/images/flags/la.png';
import lb from '../../assets/images/flags/lb.png';
import lby from '../../assets/images/flags/lby.png';
import li from '../../assets/images/flags/li.png';
import lk from '../../assets/images/flags/lk.png';
import lr from '../../assets/images/flags/lr.png';
import ls from '../../assets/images/flags/ls.png';
import lt from '../../assets/images/flags/lt.png';
import lu from '../../assets/images/flags/lu.png';
import lv from '../../assets/images/flags/lv.png';
import ma from '../../assets/images/flags/ma.png';
import mc from '../../assets/images/flags/mc.png';
import md from '../../assets/images/flags/md.png';
import me from '../../assets/images/flags/me.png';
import mg from '../../assets/images/flags/mg.png';
import mh from '../../assets/images/flags/mh.png';
import mk from '../../assets/images/flags/mk.png';
import ml from '../../assets/images/flags/ml.png';
import mm from '../../assets/images/flags/mm.png';
import mn from '../../assets/images/flags/mn.png';
import mo from '../../assets/images/flags/mo.png';
import mp from '../../assets/images/flags/mp.png';
import mq from '../../assets/images/flags/mq.png';
import mr from '../../assets/images/flags/mr.png';
import ms from '../../assets/images/flags/ms.png';
import mt from '../../assets/images/flags/mt.png';
import mu from '../../assets/images/flags/mu.png';
import mv from '../../assets/images/flags/mv.png';
import mw from '../../assets/images/flags/mw.png';
import mx from '../../assets/images/flags/mx.png';
import my from '../../assets/images/flags/my.png';
import mz from '../../assets/images/flags/mz.png';
import na from '../../assets/images/flags/na.png';
import ne from '../../assets/images/flags/ne.png';
import nf from '../../assets/images/flags/nf.png';
import ng from '../../assets/images/flags/ng.png';
import ni from '../../assets/images/flags/ni.png';
import nl from '../../assets/images/flags/nl.png';
import no from '../../assets/images/flags/no.png';
import np from '../../assets/images/flags/np.png';
import nr from '../../assets/images/flags/nr.png';
import nu from '../../assets/images/flags/nu.png';
import nz from '../../assets/images/flags/nz.png';
import om from '../../assets/images/flags/om.png';
import pa from '../../assets/images/flags/pa.png';
import pe from '../../assets/images/flags/pe.png';
import pf from '../../assets/images/flags/pf.png';
import pg from '../../assets/images/flags/pg.png';
import ph from '../../assets/images/flags/ph.png';
import pk from '../../assets/images/flags/pk.png';
import pl from '../../assets/images/flags/pl.png';
import pn from '../../assets/images/flags/pn.png';
import pr from '../../assets/images/flags/pr.png';
import ps from '../../assets/images/flags/ps.png';
import pt from '../../assets/images/flags/pt.png';
import pw from '../../assets/images/flags/pw.png';
import py from '../../assets/images/flags/py.png';
import qa from '../../assets/images/flags/qa.png';
import ra from '../../assets/images/flags/ra.png';
import ro from '../../assets/images/flags/ro.png';
import rs from '../../assets/images/flags/rs.png';
import ru from '../../assets/images/flags/ru.png';
import rw from '../../assets/images/flags/rw.png';
import sa from '../../assets/images/flags/sa.png';
import sb from '../../assets/images/flags/sb.png';
import sc from '../../assets/images/flags/sc.png';
import sct from '../../assets/images/flags/sct.png';
import sd from '../../assets/images/flags/sd.png';
import se from '../../assets/images/flags/se.png';
import sg from '../../assets/images/flags/sg.png';
import si from '../../assets/images/flags/si.png';
import sk from '../../assets/images/flags/sk.png';
import sl from '../../assets/images/flags/sl.png';
import sm from '../../assets/images/flags/sm.png';
import sn from '../../assets/images/flags/sn.png';
import so from '../../assets/images/flags/so.png';
import som from '../../assets/images/flags/som.png';
import sr from '../../assets/images/flags/sr.png';
import ssd from '../../assets/images/flags/ssd.png';
import st from '../../assets/images/flags/st.png';
import sv from '../../assets/images/flags/sv.png';
import sy from '../../assets/images/flags/sy.png';
import td from '../../assets/images/flags/td.png';
import tg from '../../assets/images/flags/tg.png';
import th from '../../assets/images/flags/th.png';
import ti from '../../assets/images/flags/ti.png';
import tj from '../../assets/images/flags/tj.png';
import tk from '../../assets/images/flags/tk.png';
import tm from '../../assets/images/flags/tm.png';
import tn from '../../assets/images/flags/tn.png';
import to from '../../assets/images/flags/to.png';
import tr from '../../assets/images/flags/tr.png';
import tt from '../../assets/images/flags/tt.png';
import tw from '../../assets/images/flags/tw.png';
import tz from '../../assets/images/flags/tz.png';
import ua from '../../assets/images/flags/ua.png';
import ug from '../../assets/images/flags/ug.png';
import uk from '../../assets/images/flags/uk.png';
import us from '../../assets/images/flags/us.png';
import uy from '../../assets/images/flags/uy.png';
import uz from '../../assets/images/flags/uz.png';
import va from '../../assets/images/flags/va.png';
import vc from '../../assets/images/flags/vc.png';
import ve from '../../assets/images/flags/ve.png';
import vi from '../../assets/images/flags/vi.png';
import vn from '../../assets/images/flags/vn.png';
import vu from '../../assets/images/flags/vu.png';
import wal from '../../assets/images/flags/wal.png';
import ws from '../../assets/images/flags/ws.png';
import xk from '../../assets/images/flags/xk.png';
import ye from '../../assets/images/flags/ye.png';
import za from '../../assets/images/flags/za.png';
import zm from '../../assets/images/flags/zm.png';
import zw from '../../assets/images/flags/zw.png';

const namesShort = {
    ad: "Andorra",
    ra: "Azores",
    ae: "United Arab Emirates",
    af: "Afghanistan",
    ag: "Antigua And Barbuda",
    ai: "Anguilla",
    al: "Albania",
    am: "Armenia",
    ao: "Angola",
    bq: "Bonaire",
    ar: "Argentina",
    asm: "American Samoa",
    at: "Austria",
    au: "Australia",
    aw: "Aruba",
    ax: "Åland",
    az: "Azerbaijan",
    ba: "Bosnia And Herzegovina",
    bb: "Barbados",
    be: "Belgium",
    bd: "Bangladesh",
    bf: "Burkina Faso",
    bg: "Bulgaria",
    bh: "Bahrain",
    bi: "Burundi",
    bj: "Benin",
    bm: "Bermuda",
    bn: "Brunei Darussalam",
    bo: "Bolivia",
    br: "Brazil",
    bs: "Bahamas",
    bt: "Bhutan",
    bw: "Botswana",
    by: "Belarus",
    bz: "Belize",
    ca: "Canada",
    cc: "Cocos (Keeling) Islands",
    cd: "Congo (Democratic Republic)",
    cf: "Central African Republic",
    cg: "Congo (Republic)",
    ch: "Switzerland",
    ci: "Ivory Coast",
    ck: "Cook Islands",
    cl: "Chile",
    cm: "Cameroon",
    cn: "China",
    co: "Colombia",
    cr: "Costa Rica",
    cu: "Cuba",
    cv: "Cape Verde",
    cx: "Christmas Island",
    cy: "Cyprus",
    cz: "Czech Republic",
    de: "Germany",
    dj: "Djibouti",
    dk: "Denmark",
    dm: "Dominica",
    dom: "Dominican Republic",
    dz: "Algeria",
    ec: "Ecuador",
    ee: "Estonia",
    eg: "Egypt",
    er: "Eritrea",
    es: "Spain",
    et: "Ethiopia",
    eU: "European Union",
    fi: "Finland",
    fj: "Fiji",
    fk: "Falkland Islands (Malvinas)",
    fm: "Micronesia, Federated States Of",
    fo: "Faroe Islands",
    fr: "France",
    ga: "Gabon",
    uk: "United Kingdom",
    gd: "Grenada",
    ge: "Georgia",
    gg: "Guernsey",
    gh: "Ghana",
    gi: "Gibraltar",
    gl: "Greenland",
    gm: "Gambia",
    gn: "Guinea",
    gq: "Equatorial Guinea",
    gr: "Greece",
    gt: "Guatemala",
    gu: "Guam",
    hk: "Hong Kong",
    hn: "Honduras",
    hr: "Croatia",
    ht: "Haiti",
    hu: "Hungary",
    xk: "Kosovo",
    id: "Indonesia",
    ie: "Ireland",
    il: "Israel",
    im: "Isle of Man",
    ind: "India",
    iq: "Iraq",
    ir: "Iran",
    is: "Iceland",
    it: "Italy",
    je: "Jersey",
    jm: "Jamaica",
    jo: "Jordan",
    jp: "Japan",
    ke: "Kenya",
    kg: "Kyrgyzstan",
    kh: "Cambodia",
    km: "Comoros",
    kn: "Saint Kitts And Nevis",
    kr: "South Korea",
    kw: "Kuwait",
    ky: "Cayman Islands",
    kz: "Kazakhstan",
    la: "Laos",
    lb: "Lebanon",
    lc: "Saint Lucia",
    li: "Liechtenstein",
    lk: "Sri Lanka",
    lr: "Liberia",
    ls: "Lesotho",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    lby: "Libya",
    ma: "Morocco",
    mc: "Monaco",
    md: "Moldova, Republic Of",
    me: "Montenegro",
    mg: "Madagascar",
    mh: "Marshall Islands",
    mk: "Macedonia",
    ml: "Mali",
    mm: "Myanmar",
    mn: "Mongolia",
    mo: "Macau",
    mp: "Northern Mariana Islands",
    mq: "Martinique",
    mr: "Mauritania",
    ms: "Montserrat",
    mt: "Malta",
    mu: "Mauritius",
    mv: "Maldives",
    mw: "Malawi",
    mx: "Mexico",
    my: "Malaysia",
    mz: "Mozambique",
    na: "Namibia",
    ne: "Niger",
    nf: "Norfolk Island",
    ng: "Nigeria",
    ni: "Nicaragua",
    nl: "Netherlands",
    no: "Norway",
    np: "Nepal",
    nr: "Nauru",
    nu: "Niue",
    nz: "New Zealand",
    om: "Oman",
    pa: "Panama",
    pe: "Peru",
    pf: "French Polynesia",
    pg: "Papua New Guinea",
    ph: "Philippines",
    pk: "Pakistan",
    pl: "Poland",
    pn: "Pitcairn",
    pr: "Puerto Rico",
    ps: "Palestine",
    pt: "Portugal",
    pw: "Palau",
    py: "Paraguay",
    qa: "Qatar",
    ro: "Romania",
    rs: "Serbia",
    ru: "Russian Federation",
    rw: "Rwanda",
    sa: "Saudi Arabia",
    sct: "Scotland",
    sb: "Solomon Islands",
    sc: "Seychelles",
    sd: "Sudan",
    ssd: "South Sudan",
    se: "Sweden",
    sg: "Singapore",
    si: "Slovenia",
    sk: "Slovakia (Slovak Republic)",
    sl: "Sierra Leone",
    sm: "San Marino",
    sn: "Senegal",
    so: "Somalia",
    som: "Somaliland",
    sr: "Suriname",
    st: "Sao Tome And Principe",
    sv: "El Salvador",
    sy: "Syria",
    td: "Chad",
    tg: "Togo",
    th: "Thailand",
    tj: "Tajikistan",
    tk: "Tokelau",
    ti: "East Timor",
    tm: "Turkmenistan",
    tn: "Tunisia",
    to: "Tonga",
    tr: "Turkey",
    tt: "Trinidad And Tobago",
    tw: "Taiwan",
    tz: "Tanzania, United Republic Of",
    ua: "Ukraine",
    ug: "Uganda",
    en: "England",
    us: "United States",
    uy: "Uruguay",
    uz: "Uzbekistan",
    va: "Vatican City State (Holy See)",
    vc: "Saint Vincent And The Grenadines",
    ve: "Venezuela",
    vi: "Virgin Islands (U.S.)",
    vn: "Vietnam",
    wal: "Wales",
    vu: "Vanuatu",
    ws: "Samoa",
    ye: "Yemen",
    za: "South Africa",
    zm: "Zambia",
    zw: "Zimbabwe",
};

const namesLong = {
    "Afghanistan": "af",
    "Albania": "al",
    "Algeria": "dz",
    "American Samoa": "asm",
    "Andorra": "ad",
    "Angola": "ao",
    "Anguilla": "ai",
    "Antigua And Barbuda": "ag",
    "Argentina": "ar",
    "Armenia": "am",
    "Aruba": "aw",
    "Australia": "au",
    "Austria": "at",
    "Azerbaijan": "az",
    "Azores": "ra",
    "Bahamas": "bs",
    "Bahrain": "bh",
    "Bangladesh": "bd",
    "Barbados": "bb",
    "Belarus": "by",
    "Belgium": "be",
    "Belize": "bz",
    "Benin": "bj",
    "Bermuda": "bm",
    "Bhutan": "bt",
    "Bolivia": "bo",
    "Bonaire": "bq",
    "Bosnia And Herzegovina": "ba",
    "Botswana": "bw",
    "Brazil": "br",
    "Brunei Darussalam": "bn",
    "Bulgaria": "bg",
    "Burkina Faso": "bf",
    "Burundi": "bi",
    "Cambodia": "kh",
    "Cameroon": "cm",
    "Canada": "ca",
    "Cape Verde": "cv",
    "Cayman Islands": "ky",
    "Central African Republic": "cf",
    "Chad": "td",
    "Chile": "cl",
    "China": "cn",
    "Christmas Island": "cx",
    "Cocos (Keeling) Islands": "cc",
    "Colombia": "co",
    "Comoros": "km",
    "Congo (Democratic Republic)": "cd",
    "Congo (Republic)": "cg",
    "Cook Islands": "ck",
    "Costa Rica": "cr",
    "Croatia": "hr",
    "Cuba": "cu",
    "Cyprus": "cy",
    "Czech Republic": "cz",
    "Denmark": "dk",
    "Djibouti": "dj",
    "Dominica": "dm",
    "Dominican Republic": "dom",
    "East Timor": "ti",
    "Ecuador": "ec",
    "Egypt": "eg",
    "El Salvador": "sv",
    "England": "en",
    "Equatorial Guinea": "gq",
    "Eritrea": "er",
    "Estonia": "ee",
    "Ethiopia": "et",
    "European Union": "eU",
    "Falkland Islands (Malvinas)": "fk",
    "Faroe Islands": "fo",
    "Fiji": "fj",
    "Finland": "fi",
    "France": "fr",
    "French Polynesia": "pf",
    "Gabon": "ga",
    "Gambia": "gm",
    "Georgia": "ge",
    "Germany": "de",
    "Ghana": "gh",
    "Gibraltar": "gi",
    "Greece": "gr",
    "Greenland": "gl",
    "Grenada": "gd",
    "Guam": "gu",
    "Guatemala": "gt",
    "Guernsey": "gg",
    "Guinea": "gn",
    "Haiti": "ht",
    "Honduras": "hn",
    "Hong Kong": "hk",
    "Hungary": "hu",
    "Iceland": "is",
    "India": "ind",
    "Indonesia": "id",
    "Iran": "ir",
    "Iraq": "iq",
    "Ireland": "ie",
    "Isle of Man": "im",
    "Israel": "il",
    "Italy": "it",
    "Ivory Coast": "ci",
    "Jamaica": "jm",
    "Japan": "jp",
    "Jersey": "je",
    "Jordan": "jo",
    "Kazakhstan": "kz",
    "Kenya": "ke",
    "Kosovo": "xk",
    "Kuwait": "kw",
    "Kyrgyzstan": "kg",
    "Laos": "la",
    "Latvia": "lv",
    "Lebanon": "lb",
    "Lesotho": "ls",
    "Liberia": "lr",
    "Libya": "lby",
    "Liechtenstein": "li",
    "Lithuania": "lt",
    "Luxembourg": "lu",
    "Macau": "mo",
    "Macedonia": "mk",
    "Madagascar": "mg",
    "Malawi": "mw",
    "Malaysia": "my",
    "Maldives": "mv",
    "Mali": "ml",
    "Malta": "mt",
    "Marshall Islands": "mh",
    "Martinique": "mq",
    "Mauritania": "mr",
    "Mauritius": "mu",
    "Mexico": "mx",
    "Micronesia, Federated States Of": "fm",
    "Moldova, Republic Of": "md",
    "Monaco": "mc",
    "Mongolia": "mn",
    "Montenegro": "me",
    "Montserrat": "ms",
    "Morocco": "ma",
    "Mozambique": "mz",
    "Myanmar": "mm",
    "Namibia": "na",
    "Nauru": "nr",
    "Nepal": "np",
    "Netherlands": "nl",
    "New Zealand": "nz",
    "Nicaragua": "ni",
    "Niger": "ne",
    "Nigeria": "ng",
    "Niue": "nu",
    "Norfolk Island": "nf",
    "Northern Mariana Islands": "mp",
    "Norway": "no",
    "Oman": "om",
    "Pakistan": "pk",
    "Palau": "pw",
    "Palestine": "ps",
    "Panama": "pa",
    "Papua New Guinea": "pg",
    "Paraguay": "py",
    "Peru": "pe",
    "Philippines": "ph",
    "Pitcairn": "pn",
    "Poland": "pl",
    "Portugal": "pt",
    "Puerto Rico": "pr",
    "Qatar": "qa",
    "Romania": "ro",
    "Russian Federation": "ru",
    "Rwanda": "rw",
    "Saint Kitts And Nevis": "kn",
    "Saint Vincent And The Grenadines": "vc",
    "Samoa": "ws",
    "San Marino": "sm",
    "Sao Tome And Principe": "st",
    "Saudi Arabia": "sa",
    "Scotland": "sct",
    "Senegal": "sn",
    "Serbia": "rs",
    "Seychelles": "sc",
    "Sierra Leone": "sl",
    "Singapore": "sg",
    "Slovakia (Slovak Republic)": "sk",
    "Slovenia": "si",
    "Solomon Islands": "sb",
    "Somalia": "so",
    "Somaliland": "som",
    "South Africa": "za",
    "South Korea": "kr",
    "South Sudan": "ssd",
    "Spain": "es",
    "Sri Lanka": "lk",
    "Sudan": "sd",
    "Suriname": "sr",
    "Sweden": "se",
    "Switzerland": "ch",
    "Syria": "sy",
    "Taiwan": "tw",
    "Tajikistan": "tj",
    "Tanzania, United Republic Of": "tz",
    "Thailand": "th",
    "Togo": "tg",
    "Tokelau": "tk",
    "Tonga": "to",
    "Trinidad And Tobago": "tt",
    "Tunisia": "tn",
    "Turkey": "tr",
    "Turkmenistan": "tm",
    "Uganda": "ug",
    "Ukraine": "ua",
    "United Arab Emirates": "ae",
    "United Kingdom": "uk",
    "United States": "us",
    "Uruguay": "uy",
    "Uzbekistan": "uz",
    "Vanuatu": "vu",
    "Vatican City State (Holy See)": "va",
    "Venezuela": "ve",
    "Vietnam": "vn",
    "Virgin Islands (U.S.)": "vi",
    "Wales": "wal",
    "Yemen": "ye",
    "Zambia": "zm",
    "Zimbabwe": "zw",
    "Åland": "ax",
}

export {
    ad,
    ae,
    af,
    ag,
    ai,
    al,
    am,
    ao,
    ar,
    asm,
    at,
    au,
    aw,
    ax,
    az,
    ba,
    bb,
    bd,
    be,
    bf,
    bg,
    bh,
    bi,
    bj,
    bm,
    bn,
    bo,
    bq,
    br,
    bs,
    bt,
    bw,
    by,
    bz,
    ca,
    cc,
    cd,
    cf,
    cg,
    ch,
    ci,
    ck,
    cl,
    cm,
    cn,
    co,
    cr,
    cu,
    cv,
    cx,
    cy,
    cz,
    de,
    dj,
    dk,
    dm,
    dom,
    dz,
    ec,
    ee,
    eg,
    en,
    er,
    es,
    et,
    eU,
    fi,
    fj,
    fk,
    fm,
    fo,
    fr,
    ga,
    gd,
    ge,
    gg,
    gh,
    gi,
    gl,
    gm,
    gn,
    gq,
    gr,
    gt,
    gu,
    hk,
    hn,
    hr,
    ht,
    hu,
    id,
    ie,
    il,
    im,
    ind,
    iq,
    ir,
    is,
    it,
    je,
    jm,
    jo,
    jp,
    ke,
    kg,
    kh,
    km,
    kn,
    kr,
    kw,
    ky,
    kz,
    la,
    lb,
    lby,
    li,
    lk,
    lr,
    ls,
    lt,
    lu,
    lv,
    ma,
    mc,
    md,
    me,
    mg,
    mh,
    mk,
    ml,
    mm,
    mn,
    mo,
    mp,
    mq,
    mr,
    ms,
    mt,
    mu,
    mv,
    mw,
    mx,
    my,
    mz,
    na,
    ne,
    nf,
    ng,
    ni,
    nl,
    no,
    np,
    nr,
    nu,
    nz,
    om,
    pa,
    pe,
    pf,
    pg,
    ph,
    pk,
    pl,
    pn,
    pr,
    ps,
    pt,
    pw,
    py,
    qa,
    ra,
    ro,
    rs,
    ru,
    rw,
    sa,
    sb,
    sc,
    sct,
    sd,
    se,
    sg,
    si,
    sk,
    sl,
    sm,
    sn,
    so,
    som,
    sr,
    ssd,
    st,
    sv,
    sy,
    td,
    tg,
    th,
    ti,
    tj,
    tk,
    tm,
    tn,
    to,
    tr,
    tt,
    tw,
    tz,
    ua,
    ug,
    uk,
    us,
    uy,
    uz,
    va,
    vc,
    ve,
    vi,
    vn,
    vu,
    wal,
    ws,
    xk,
    ye,
    za,
    zm,
    zw,
    namesShort,
    namesLong
};