import React from 'react';
import styles from './pagination.module.css'

const Pagination = (props) => {
    let buttonsHtml;
    let doWeRender = ((props.data) && (props.data.length > 0) ? true : false)
    let activeButton = (props.activePage || 1)

    const clickHandler = async (num) => {
        await props.pageClickHandler(num)
    }

    // Compare active num with last 4 array slots
    const checkData = () => {

        let a = props.data
        let btnsToGen = a.length
        let max = props.maxPages

        a.slice(-1) && btnsToGen++
        a.slice(-2) && btnsToGen++
        a.slice(-3) && btnsToGen++
        a.slice(-4) && btnsToGen++

        if (max && (btnsToGen > max)) {
            btnsToGen = max
        }

        
        return btnsToGen
    }

    if (doWeRender) {
        let buttons = []
        let buttonsToGenerate = checkData()
        let base;
        // works let base = activeButton > 5 ? (activeButton - 4) : 1

        if (activeButton > 5 && ((activeButton + 4) < buttonsToGenerate) && buttonsToGenerate > 9) {
            base = activeButton - 4
        } else if ((activeButton > 5) && ((activeButton + 4) >= buttonsToGenerate) && buttonsToGenerate > 9) {
            base = buttonsToGenerate - 8
        } else {
            base = 1
        }


        for (let i = 0; (i+1 <= buttonsToGenerate) && (i < 9); i++) {
            let currentNumber = base+i

            buttons.push(
                <li 
                    key={i} 
                    className={ (currentNumber === activeButton) ? `${styles.button} ${styles.active}` : styles.button }
                    // onClick={() => generateButtons(currentNumber)}
                    onClick={() => clickHandler(currentNumber)}
                    >
                    
                {currentNumber}</li>
            )
        }

        const arrows = (num) => {
            if (
                (activeButton + num) >= 1 && 
                (num + activeButton) <= buttonsToGenerate)
                {
                // generateButtons(activeButton + num)
                clickHandler(activeButton + num)
            }
        }

        buttonsHtml = (
            <div className={styles.container}>
                <span className={`${styles.button} ${styles.button_left}`} onClick={() => arrows(-1)} >
                    «
                    </span>
                    <ul>{buttons}</ul>
                    <span className={`${styles.button} ${styles.button_right}`} onClick={() => arrows(1)} >
                    »
                </span>
            </div>
        )
    }

    return buttonsHtml ||null
}

export default Pagination