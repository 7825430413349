import React, { useState } from 'react';
import axios from 'axios'

import styles from './settings.module.css'
import { AJAX } from '../config'

const ManualRecord = (props) => {

    const [map, setMap] = useState(null)
    const [time, setTime] = useState(null)
    const [player, setPlayer] = useState(null)
    const [steamid, setSteamid] = useState(null)
    const [sync, setSync] = useState(null)
    const [strafes, setStrafes] = useState(null)
    const [jumps, setJumps] = useState(null)
    const [date, setDate] = useState(null)
    const [ip, setIp] = useState(null)
    const [hostname, setHostname] = useState(null)
    const [videoId, setVideoId] = useState(null)
    const [saveButton, setSaveButton] = useState('Save')

    const onSubmitHandler = async (event) => {
        event.preventDefault();
            setSaveButton('Saving...')

            try {
                const config = {
                    url: AJAX + 'admin/addtime',
                    method: 'post',
                    withCredentials: true,
                    data: { map, time, player, steamid, sync, strafes, jumps, date, ip, hostname, videoId }
                };
                await axios.request(config); 
                setSaveButton('Saved')
            } catch (error) {
                setSaveButton('Error saving')
            }

    }

    const makeTextInput = (name, desc, setInputState) => {
        return (
            <div className={styles.option}>
                {name} <input 
                    type="text" 
                    placeholder={desc} 
                    className={styles.box} 
                    maxLength="32" 
                    onChange={(event) => setInputState(event.target.value)}
                    />
            </div>
        )
    }
    
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.options}>
                    {makeTextInput('Map', 'Full map name', setMap)}
                    {makeTextInput('Time', 'In seconds, ex: 103.642', setTime)}
                    {makeTextInput('Player', 'Player name', setPlayer)}
                    {makeTextInput('Steam ID', 'Any Steam ID format', setSteamid)}
                    {makeTextInput('Sync', 'Average %, numbers only', setSync)}
                    {makeTextInput('Strafes', 'Amount of strafes', setStrafes)}
                    {makeTextInput('Jumps', 'Amount of jumps', setJumps)}
                    {makeTextInput('Date', 'YYYY-MM-DD HH:MM:SS', setDate)}
                    {makeTextInput('Server IP', '127.0.0.1 for LAN', setIp)}
                    {makeTextInput('Hostname', 'Dystopia, Kawaii, etc.', setHostname)}
                    {makeTextInput('Video', 'YouTube ID', setVideoId)}
                    <div className={styles.save} onClick={onSubmitHandler}>
                        <div className={styles.button}>{saveButton}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ManualRecord