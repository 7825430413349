import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useUser } from '../../../UserContext'
import styles from "./top.module.css";
import xmas from "./top-christmas.module.css";
import { YTLogo, DiscordLogo, SteamLogo, SignInLogo} from '../../shared/image-imports'
import { signOut } from "../middle/pages/players/players";
import { isXmas } from '../../shared/funcs'

const Top = (props) => {

    const [dropDownEnabled, setDropDownEnabled] = useState(false)
    const user = useUser();
    let signIn;
    let mobileSignIn;

    if (user) {
        const signedIn = <Link to={"/players/" + user.steamid}>
                            <span className={styles.list_item_wrapper}>
                                <img className={styles.pic} src={user.avatar} alt=""/>
                                <p>{user.name}</p>
                            </span>
                        </Link>
        signIn = signedIn
        mobileSignIn = signedIn
    } else {
        const signedOut = <Link to="/signin">
                            <span className={styles.list_item_wrapper}>
                                <img className={`${styles.pic} ${styles.signed_out}`} src={SignInLogo} alt=""/>
                                <p>Sign in</p>
                            </span>
                        </Link>
        signIn = signedOut
        mobileSignIn = signedOut
    }

    const mobileNav = (
        <div className={`${styles.top_mobile} ${!dropDownEnabled ? styles.shadow : ''}`} onClick={(e) => setDropDownEnabled(oldState => !oldState)}>
            <div className={styles.menu_icon}>
                <div></div>
                <div></div>
                <div></div> 
            </div>
            <span className={styles.menu_link} onClick={e => {e.preventDefault(); e.stopPropagation(); dropDownEnabled && setDropDownEnabled(false)}}>{mobileSignIn}</span>
        </div>
    )

    const youtube = (
        <a href="https://www.youtube.com/user/SourceJump?sub_confirmation=1" rel="noopener noreferrer" target="_blank">
            <span className={styles.list_item_wrapper}>
                <img className={styles.pic} src={YTLogo} alt=""/>
                <p>YouTube</p>
            </span>
        </a>
    )

    const discord = (
        <a href="https://discord.com/invite/nP5YzB3" rel="noopener noreferrer" target="_blank">
            <span className={styles.list_item_wrapper}>
                <img className={styles.pic} src={DiscordLogo} alt=""/>
                <p>Discord</p>
            </span>
        </a>
    )

    const steam = (
        <a href="https://steamcommunity.com/groups/SourceJump" rel="noopener noreferrer" target="_blank">
            <span className={styles.list_item_wrapper}>
                <img className={styles.pic} src={SteamLogo} alt=""/>
                <p>Steam</p>
            </span>
        </a>
    )

    const dropDownMenu = dropDownEnabled ? (
        <div className={`${styles.dropdown_menu} ${dropDownEnabled ? styles.shadow : ''}`} onClick={() => setDropDownEnabled(false)}>
            <div className={styles.dropdown_divider}>
                <ul>
                    <li><Link to="/home">SourceJump</Link></li>
                    <li><Link to="/records">Records</Link></li>
                    <li><Link to="/leaderboards">Leaderboards</Link></li>
                    <li><Link to="/players">Players</Link></li>
                    <li><Link to="/community">Community</Link></li>
                    <li><Link to="/servers">Servers</Link></li>
                </ul>
                <ul>
                    <li>{youtube}</li>
                    <li>{discord}</li>
                    <li>{steam}</li>
                    {user && <li onClick={() => signOut()} style={{'color': 'rgb(231, 231, 231)', cursor: "pointer"}}>
                                    <span className={styles.list_item_wrapper}>
                                        <img className={styles.pic} src={SignInLogo} alt=""/>
                                        <p>Sign out</p>
                                    </span>
                                </li>
                        }
                </ul>
            </div>
        </div>
    ) : null



    const xmasLightsGenerator = (amount, mobile) => {
        let lis = []
        for (let i = 0; i < (amount || 25); i++) {
            lis.push(<li key={i+'L'}></li>)
        }
        return (
            <div className={`${styles.xmas_lights} ${mobile ? styles.xmas_lights_mobile : ''}`}>
                <ul id={xmas.wire}>
                    {lis}
                </ul>
            </div>
        )
    }

    const xmasHtml = xmasLightsGenerator()
    const xmasHtmlMobile = xmasLightsGenerator(15, true)

    return (
        <header>
            <div className={styles.topnav}>
                <div className={styles.link_wrapper}>
                    <ul className={styles["nav-links"]}>
                        <li><Link to="/home">SourceJump</Link></li>
                        <li><Link to="/records">Records</Link></li>
                        <li><Link to="/leaderboards">Leaderboards</Link></li>
                        <li><Link to="/players">Players</Link></li>
                        <li><Link to="/community">Community</Link></li>
                        <li><Link to="/servers">Servers</Link></li>
                    </ul>
                    <ul className={`${styles["nav-links"]} ${styles.t}`}>
                        <li>{youtube}</li>
                        <li>{discord}</li>
                        <li>{steam}</li>
                        <li>
                            {signIn}
                        </li>
                    </ul>
                </div>
            </div>
            {mobileNav}
            {dropDownMenu}
            {isXmas && xmasHtml}
            {isXmas && xmasHtmlMobile}
        </header>
    );
};
export default Top;
