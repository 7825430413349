import React from 'react';
import { Helmet } from "react-helmet-async";

import styles from './api.module.css'

const API = (props) => {

    const createApiEndpoint = (config) => {
        return (
            <div className={styles.endpoint_container}>
                <Helmet>
                    <title>API</title>
                    <meta name="description" content="SourceJump API" />
                </Helmet>
                <div className={styles.endpoint_container_top}>
                    <span className={styles.bold}>GET</span> /api/{config.endpoint}?key={'{api_key}'}
                </div>
                <div className={styles.endpoint_container_bottom}>
                    <div>
                        <p className={styles.title}>{config.desc}</p>
                    </div>
                    {config.onSuccess}
                    <div>
                        <p className={styles.title}>Response info</p>
                        <pre className={styles.code}>{config.responseInfo}</pre>
                    </div>
                </div>
            </div>
        )
    }

    const configRecords = {
        endpoint: 'records/{map}',
        desc: 'Top times on map',
        onSuccess: `
            Returns an array of objects
            containing top server times on a given map,
            sorted by times ascending,
            excluding invalid times and banned players.
            The 'bhop_' prefix of the map name may be omitted.`,
        responseInfo: `"id": Run ID (SJ database reference)
"name": Player name or alias
"country": Country initials, null if unset
"map": Full, lowercase map name
"hostname": Name of the server
"time": Run time; SECONDS:MILLISECONDS
"wrDif": WR time difference
"steamid": Player steamid3
"tier": Map tier (0-10),
"date": "YEAR-MONTH-DAY"
"sync": Average strafe sync
"strafes": Amount of strafes
"video": YouTube video ID
"points": SJ points awared for maintaining WR
"jumps": Amount of jumps`
}

    const configBanned = {
        endpoint: 'players/banned',
        desc: 'Get banned players',
        onSuccess: `
            Returns an array of objects
            containing all players banned for cheating.`,
        responseInfo: `"name": Player name
"steamid": Player steamid3
"ban_date": "YEAR-MONTH-DAY TIME"
"country": Country initials, null if unset`
}

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <div className={styles.content}>
                    <h1 style={{marginBottom: '10px'}}>SourceJump API</h1>
                    <p>To use the API you need an API key.</p>
                    <p>You can get a key by joining the Discord and asking Tony Montana.</p>
                </div>
                <div className={styles.endpoints}>
                    {createApiEndpoint(configRecords)}
                    {createApiEndpoint(configBanned)}
                </div>
            </div>
        </div>
    )
}
export default API